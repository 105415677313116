import logo from './logo.svg';
import './App.css';
import VoiceRecorder from './modules/VoiceRecorder';
import axios from 'axios';
import { useState } from 'react';

function App() {
  const [loading, setLoading] = useState(false);
  const [transcript, setTranscript] = useState({});
  const handleFileChange = async (e) => {

    const file = e.target.files[0];
    console.log(file);
    
    const formData = new FormData();
    formData.append('audio', file, file.name);
    
    try {
      setLoading(true);
      const response = await axios.post(process.env.REACT_APP_API_URL+'voice', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('API response:', response.data);
      setLoading(false);
      setTranscript(response.data.result);
    } catch (error) {
      console.error('Error sending audio to API:', error);
    }
  }

  return (
    <div className="App p-5">
      <div className='grid grid-cols-2 gap-6 mx-[20%]'>
        <div className="border bg-slate-200 rounded-xl p-5">
          <h1 className='text-[12pt] font-[500]'>Ghi âm</h1>
          <VoiceRecorder />
        </div>
        <div className="border bg-slate-200 rounded-xl p-5">
          <h1 className='pb-5 text-[12pt] font-[500]'>Hoặc upload file</h1>
          <input type="file" style={{border:'1px solid #ccc', borderRadius:'4px', backgroundColor:'#fff'}} accept="audio/wav" onChange={handleFileChange} />
        </div>
      </div>
      <div className='grid grid-cols-1 gap-6 mt-3'>
        {loading && <p>Đang xử lý...</p>}
        {!loading && transcript && transcript.chunks && 
        <div className="border text-left rounded-xl p-5">
          <h1 className='text-[12pt] font-[500] border-b mb-2 pb-2'>Kết quả:</h1>
            <div className='grid grid-cols-1 gap-6'>
            {transcript.chunks.map((chunk, index) => (
              <div className='pb-2' key={index}>
                <div className='text-right text-[10pt] text-gray-500'>{chunk.start} - {chunk.end}</div>
                <p>{chunk.transcription}</p>
              </div>
            ))}
            </div>
        </div>
        }
      </div>
    </div>
  );
}

export default App;
