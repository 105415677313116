import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const VoiceRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const checkInterval = useRef(null);
  const lastVoiceActivityTime = useRef(Date.now());
  const audioContext = useRef(null);
  const analyser = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);

      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      analyser.current = audioContext.current.createAnalyser();
      analyser.current.fftSize = 2048;
      const source = audioContext.current.createMediaStreamSource(stream);
      source.connect(analyser.current);

      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };

      mediaRecorder.current.start(1000); // Collect data every second
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
      mediaRecorder.current.stop();
      setIsRecording(false);
      clearInterval(checkInterval.current);
      audioContext.current.close();
      sendAudioToAPI(); // Uncomment this line to send the last chunk
    }
  };

  const sendAudioToAPI = async () => {
    if (audioChunks.current.length === 0) return;

    const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
    const formData = new FormData();
    formData.append('audio', audioBlob, 'recording-' + Date.now() + '.wav');

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL+'voice', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('API response:', response.data);
    } catch (error) {
      console.error('Error sending audio to API:', error);
    }
  };

  useEffect(() => {
    return () => {
      if (isRecording) {
        stopRecording();
      }
    };
  }, [isRecording]);

  return (
    <div className="p-4">
      <button
        onClick={isRecording ? stopRecording : startRecording}
        className={`px-4 py-2 rounded ${
          isRecording
            ? 'bg-red-500 hover:bg-red-600'
            : 'bg-blue-500 hover:bg-green-600'
        } text-white font-bold`}
      >
        {isRecording ? 'Stop' : 'Start'} Recording
      </button>
      {isRecording && <p className="mt-2">Recording in progress...</p>}
    </div>
  );
};

export default VoiceRecorder;